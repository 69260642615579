import {numberDecimals, formatFloat} from '@/utils';
import gql from 'graphql-tag';
import {apolloClient} from '@/utils/apollo';
import {getLpTokenAData, getLpToken} from '@/utils/calculate';

const protocolMetricsGql = gql`
    {
        protocolMetrics(first: 1, orderBy: timestamp, orderDirection: desc) {
            timestamp
            gyroCirculatingSupply
            sGyroCirculatingSupply
            totalSupply
            gyroPrice
            marketCap
            totalValueLocked
            treasuryMarketValue
            treasuryRiskFreeValue
            runwayCurrent
            currentAPY
            nextEpochRebase
            nextRebaseRewards
        }
    }
`;

const state = {
    apy: 0,
    marketPrice: 0,
    tvl: 0,
    gyroStaked: 0,
    marketCap: 0,
    circulatingSupply: 0,
    treasuryBalance: 0,
    nextRebase: 0,
    runway: 0,
    lpTokenA: null,
    lpTokenB: null,
    gGyroIndex: 0,
    gGyroPrice: 0
};

const mutations = {
    SET_APY: (state, apy) => {
        state.apy = apy;
    },
    SET_TVL: (state, tvl) => {
        state.tvl = tvl;
    },
    SET_GYRO_STAKED: (state, gyroStaked) => {
        state.gyroStaked = gyroStaked;
    },
    SET_MARKET_CAP: (state, marketCap) => {
        state.marketCap = marketCap;
    },
    SET_CIRCULATING_SUPPLY: (state, circulatingSupply) => {
        state.circulatingSupply = circulatingSupply;
    },
    SET_TREASURY_BALANCE: (state, treasuryBalance) => {
        state.treasuryBalance = treasuryBalance;
    },
    SET_RUNWAY: (state, runway) => {
        state.runway = runway;
    },
    SET_MARKETPRICE: (state, marketPrice) => {
        state.marketPrice = marketPrice;
    },
    SET_LP_TOKENA: (state, lpTokenA) => {
        state.lpTokenA = lpTokenA;
    },
    SET_G_GYRO_INDEX: (state, gGyroIndex) => {
        state.gGyroIndex = gGyroIndex;
    },
    SET_G_GYRO_PRICE: (state, gGyroPrice) => {
        state.gGyroPrice = gGyroPrice;
    },
    SET_LP_TOKENB: (state, lpTokenB) => {
        state.lpTokenB = lpTokenB;
    },
    SET_NEXT_REBASE: (state, nextRebaseYield) => {
        state.nextRebaseYield = nextRebaseYield;
    }
};

const actions = {
    // Update some important statistics
    updateDash({commit}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const {lpTokenContract, gyroVaultContract, sGyroContract, gGyroContract, provider} = this.state.wallet;
                const {lpTokenA, lpTokenB} = state;
                let marketPrice = 0; // Gyro Price
                if (!lpTokenA && !lpTokenB) {
                    const {A, B} = await getLpToken(lpTokenContract, provider);
                    commit('SET_LP_TOKENA', A);
                    commit('SET_LP_TOKENB', B);
                    marketPrice = await getLpTokenAData(A, B, provider);
                } else {
                    marketPrice = await getLpTokenAData(lpTokenA, lpTokenB, provider);
                }
                commit('SET_MARKETPRICE', parseFloat(marketPrice).toFixed(2));
                const response = await apolloClient.query({
                    query: protocolMetricsGql,
                    fetchPolicy: 'network-only'
                });
                const {protocolMetrics} = response.data;
                if (protocolMetrics.length > 0) {
                    const treasuryData = JSON.parse(JSON.stringify(protocolMetrics[0]));
                    const {
                        gyroCirculatingSupply,
                        sGyroCirculatingSupply,
                        totalValueLocked,
                        totalSupply,
                        runwayCurrent,
                        treasuryMarketValue
                    } = treasuryData;
                    // console.log({gyroCirculatingSupply});
                    // console.log({sGyroCirculatingSupply});

                    // const gyroCircSupply = formatFloat(await gyroContract.circulatingSupply());
                    // console.log({gyroCircSupply});
                    const sGyroV2CircSupply = formatFloat(await sGyroContract.circulatingSupply());
                    // console.log({sGyroV2CircSupply});
                    const epoch = await gyroVaultContract.epoch();
                    // console.log({epoch});
                    const gGyroIndex = await gGyroContract.index();

                    // commit('SET_APY', numberDecimals(currentAPY));
                    commit('SET_G_GYRO_INDEX', numberDecimals(formatFloat(gGyroIndex)));
                    commit('SET_G_GYRO_PRICE', numberDecimals(parseFloat(marketPrice).toFixed(2) * formatFloat(gGyroIndex), 100));

                    commit('SET_TVL', numberDecimals(totalValueLocked));
                    commit('SET_GYRO_STAKED', ((sGyroCirculatingSupply / gyroCirculatingSupply) * 100).toFixed(2));
                    commit('SET_MARKET_CAP', numberDecimals(marketPrice * totalSupply));
                    commit('SET_CIRCULATING_SUPPLY', numberDecimals(totalSupply));
                    commit('SET_TREASURY_BALANCE', numberDecimals(treasuryMarketValue));
                    commit('SET_RUNWAY', numberDecimals(runwayCurrent));

                    // const nextRebaseYield = nextRebaseRewards / sGyroCirculatingSupply;
                    const nextRebaseYield = formatFloat(epoch.distribute) / sGyroV2CircSupply;
                    // console.log({nextRebaseYield});

                    commit('SET_NEXT_REBASE', nextRebaseYield);
                    let apy = numberDecimals((Math.pow(parseFloat(nextRebaseYield) + 1, 365 * 3 - 1) - 1) * 100);
                    apy =
                        apy > 1000000
                            ? `${(apy / 1000000).toLocaleString('en', {minimumFractionDigits: 4})}M`
                            : apy.toLocaleString('en', {minimumFractionDigits: 4});
                    commit('SET_APY', apy);
                }
                resolve(true);
            } catch (error) {
                resolve(true);
                reject(true);
                console.error('get apollo protocolMetrics error', error);
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
