export default {
    portfolio: '统计',
    dex: '购买',
    privateSaleTitle: 'GYRO 私募',
    stake: '质押',
    bond: '债券',
    unstake: '赎回',
    combination: '组合债券',
    single: '单币',
    connectWallet: '连接钱包',
    docs: '文档',
    code: '代码',
    telegram: '电报',
    vote: '投票',
    myWallet: '您的钱包',
    switchWallet: '切换钱包',
    amount: '金额',
    max: '最大',
    usdtBalance: '我的 USDT 余额',
    userPGyroBalance: '我的 pre-Gyro 余额',
    purchase: '认购',
    approve: '授权',
    transactionSuccess: '交易成功',
    transactionFailed: '交易失败',
    getAllowanceFailed: '获取授权信息失败',
    approveSuccess: '授权成功',
    approveFailed: '授权失败',
    getPrivateSaleInfoFailed: '获取 pre-Gyro 信息失败',
    greaterThanZero: '请输入大于0的数值',
    vGyroOfferingTitle: 'GYRO IDO',
    userVGyroBalance: '我的 vGyro 余额',
    vGyroPrice: 'vGyro 价格',
    exchange: '兑换 pGYRO',
    exchangeSuccess: '兑换成功',
    exchangeFailed: '兑换失败',
    redemptionRatio: '兑换比例',
    balance: '余额',
    walletBalance: '钱包余额',
    myStake: '我的质押',
    hour: '小时',
    minute: '分钟',
    days: '天',
    currentIndex: '当前指数',
    timeUntilRebase: '下次分拆时间',
    nextRebase: '下次分拆收益率',
    roi5: '5日投资回报率',
    apy: '年化收益率',
    bondTitle: '如何购买债券',
    bondMsg: '债券给您有机会以折扣价格购买 Gyro 代币。 所有债券需要经过5天时间逐步提取',
    addLiquidity: '添加流动性',
    pendingRewards: '待奖励',
    claimableRewards: '可领取的奖励',
    vestedTime: '剩余分发时间',
    debtRatio: '资产负债率',
    vestingTerm: '分发周期',
    roi: '投资回报率',
    bondPrice: '债券价格',
    marketPrice: '市场价',
    slippageTolerance: '滑点',
    buyBond: '购买',
    bondRedeem: '提取',
    transaction: '交易',
    maxMsg: '您输入的金额大于最大存入金额',
    minMsg: '您输入的金额小于最小存入金额',
    totalStaked: '总抵押',
    removeLiquidity: '移除流动性',
    fast: '快捷存入',
    invitationCode: '邀请码（选填）',
    discount: '折扣',
    createMyCode: '我的推荐码',
    price: '价格',
    buy: '购买',
    codeMsg: '购买后将获得推荐码',
    settings: '设置',
    slippage: '滑点',
    slippageMsg: '如果价格变化超过滑动百分比，则可以恢复交易',
    toggleExpertMode: '切换专家模式',
    recipientAddress: '收件人地址',
    recipientAddressMsg: '选择收件人地址。 默认情况下，这是您当前连接的地址',
    myRewards: '显示奖励',
    claimMyRewards: '提取奖励',
    create: '创建',
    reward: '奖励',
    rewardMsg: '请输入您要提取的邀请码',
    gyroPrice: 'GYRO 价格',
    tvl: '总锁定价值',
    gyroStaked: 'GYRO 质押率',
    marketCap: '总市值',
    gyroTotalSupply: '发行量',
    treasuryBalance: '金库余额',
    runway: '奖励可分发天数',
    stakeIncome: '下次奖励金额',
    whatYou: '你会得到什么',
    maxCanPurchase: '最大可以购买',
    lpBond: '组合债券',
    redeemableNow: '可领取奖励',
    rewardsRemaining: '剩余奖励',
    discord: 'Discord',
    buyGyro: '购买GYRO',
    singleBond: '单资产债券',
    pairBond: '配对资产债券',
    pair: '双币',
    lp: '组合',
    bondDescribe: '用单一资产购买LP债券',
    bondDescribe1: '购买LP债券与配对资产',
    bondDescribe2: '用LP标记购买债券',
    bondDescribe3: '用单一资产购买债券',
    bonds: '债券列表',
    BondsExtracted: '待提取债券',
    riskFreeValue: '协议收益',
    getInfoDataError: '获取详细数据错误',
    bondSuccess: '购买债券成功',
    bondError: '购买债券失败',
    redeemSuccess: '领取债券成功',
    redeemError: '领取债券失败',
    optional: '可选填',
    unstakeV1: 'v1赎回',
    migrationCountdown: '迁移倒计时',
    migrationDescribeTitle: '48小时的迁移窗口',
    migrationDescribeText: '在迁移过程中，V1抵押的APY将不断减少，并在6周期（48小时）逐渐增加V2抵押的APY。',
    migrationDescribeStep1: '赎回v1中的sGYRO',
    migrationDescribeStep2: '在v2中质押GYRO',
    wrapAndUnwrap: '兑换 gGYRO',
    wrap: '兑换 gGYRO',
    unwrap: '赎回 sGYRO',
    tokenSelect: '选择代币'
};
