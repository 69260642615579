import tokenLists from './tokenLists';

// Trading pool contract address
const POOLS_CONFIG = {
    [56]: {
        PrivateSale: '0xE57F45482cC5164686B2A7070Baa72F8A4BB59eB',
        VGyroOffering: '0x726477a080765745873C3d2012556eAc188A181f',
        VGyroVault: '0x214DD50C57e41913D6A366d8B49295d072913e07',
        PGyroVault: '0x1DC21e093D933971C45Af678f0baE384c343f8dE',
        GyroVault: '0x96a8FC2D80F464Bd734Ce8ED31Bd34a48176e5a7',
        Distributor: '0xe863EF77b34CB50Ca379d4b33f252751c0c69825',
        LpToken: '0x5ca063a7e2bebefeb2bdea42158f5b825f0f9ffb',
        LpBond: '0xd6b1997149f1114f6251B6df1D907770Ba6df819',
        BondCalculator: '0xF14a3eefa14C43E2FfF341F8B40BEe924121FEC5',
        Referral: '0x9116cb9930e0f7C5bc35e33f17F6b892Af6E763a',
        Reservoir: '0x8B1522402FECe066d83E0F6C97024248Be3C8c01'
    },
    [97]: {
        PrivateSale: '0xC4Bb61D7149F455185674285472921336E856856',
        VGyroOffering: '0x200231809A69219eDD12269F42d9AF17F7FF22EE',
        VGyroVault: '0x61B034746072eBC3254Fd818153A47344F203EAB',
        PGyroVault: '0xf70a0E585E024954157A382b0Fd3455f6D49c1c5',
        GyroVault: '0xFb62A381eC59cF4844F01c65C6d31AC42Cc64ab8',
        Distributor: '0x472198C10b3720C8aF25031e7Fd3a313eFbdFfbF',
        LpToken: '0x37dce4361cc268bd35817c7e6c025f90beae24e4',
        LpBond: '0x7E84897f132AB760FB90bdfBC67729DbE95bb52f',
        BondCalculator: '0xECdb1536b4dea25561239694Da0fE81d380055bd',
        Referral: '0x2B72690683Ca1400C0ac569414eeE7E4C71F4bfe',
        Reservoir: '0xB8260AFf40321Afe86B5618D70cB34D661B9b3b1'
    }
};

// bond contract address
const BOND_CONFIG = {
    [56]: {
        Busd: {
            bondAddr: '0x8885e2493ef85bf5e75DbD2Db5047b4Eb72d47Ae',
            tokenAddr: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
        },
        GyroBusd: {
            bondAddr: '0xe259A3dFc976b43815C43723ecACB5c1E8794518',
            tokenAddr: '0xA5399084a5F06d308C4527517bbB781c4dCe887c',
            bondHelperAddr: '0x170Da1A957e816aA030dE37b71Ba0e9746afC5eF'
        },
        GyroUsdt: {
            bondAddr: '0xd6b1997149f1114f6251B6df1D907770Ba6df819',
            tokenAddr: '0x5ca063a7e2bebefeb2bdea42158f5b825f0f9ffb',
            bondHelperAddr: '0x170Da1A957e816aA030dE37b71Ba0e9746afC5eF'
        }
    },
    [97]: {
        Usdt: {
            bondAddr: '0xec6FB284Be5c734F8EE5396A31B521D8439E3408',
            tokenAddr: '0xdab5d4634da56f3d5e84dc9e908bf9f7b8610e27'
        },
        Busd: {
            bondAddr: '',
            tokenAddr: ''
        },
        GyroBusd: {
            bondAddr: '',
            tokenAddr: ''
        },
        GyroUsdt: {
            bondAddr: '0x7E84897f132AB760FB90bdfBC67729DbE95bb52f',
            tokenAddr: '0x37dce4361cc268bd35817c7e6c025f90beae24e4',
            bondHelperAddr: '0x7d353A7488C721ef972B73515Fc57Eb3b09FF357',
            singleTokenAddr: '0xDAb5D4634DA56f3d5E84DC9E908BF9F7b8610E27'
        }
    }
};

// Main network configuration
export const NETWORK_CONFIG = {
    [56]: 'https://bsc-dataseed.binance.org:443',
    [97]: 'https://data-seed-prebsc-1-s1.binance.org:8545'
};

export const BASE_BSC_SCAN_URLS = {
    [56]: 'https://bscscan.com',
    [97]: 'https://testnet.bscscan.com'
};

// Statistics interface address
export const APOLLO_API = {
    [56]: 'https://api.thegraph.com/subgraphs/name/gyro-defi/gyro-v2',
    [97]: 'https://api.thegraph.com/subgraphs/name/gyro-defi/gyro-v2'
};

// multicall multicall address
export const MULTICALL_CONFIG_ADDRESS = {
    [56]: '0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D',
    [97]: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576'
};

// chain id
export const CHAIN_ID = process.env.VUE_APP_CHAIN_ID;

// network url
export const NETWORK_URL = process.env.VUE_APP_NETWORK_URL || '';

// Get the Token Contract Address
function constructSameTokenAddressMap(chainId) {
    const token = {};
    tokenLists.tokens.forEach(element => {
        if (element.chainId == chainId) token[element.symbol] = element.address;
    });
    return token;
}

// Get the Map Contract Address
function constructSameAddressMap(map, chainId) {
    return map[[chainId]];
}

/**
 * Number of digits according to the token
 * @param {String} comparison comparison value
 * @param {String} type comparison key Default: 'address' Optional: ['address', 'symbol']
 * @returns Number
 */
export function getDecimals(comparison, type = 'address') {
    const token = tokenLists.tokens.find(element => element[type] === comparison);
    if (token) return token.decimals;
    return '';
}

/**
 * According to the symbol query token
 * @param {String} symbol symbol value
 * @returns Token
 */
export function getSymbol(symbol) {
    const tokens = [];
    tokenLists.tokens.forEach(element => {
        if (element.chainId == CHAIN_ID) tokens.push(element);
    });
    const token = tokens.find(element => element.symbol.toLowerCase() === symbol.toLowerCase());
    if (token) return token;
    return '';
}

/**
 * According to the address query token
 * @param {String} address address value
 * @returns Token
 */
export function getToken(address) {
    const tokens = [];
    tokenLists.tokens.forEach(element => {
        if (element.chainId == CHAIN_ID) tokens.push(element);
    });
    const token = tokens.find(element => element.address.toLowerCase() === address.toLowerCase());
    if (token) return token;
    return '';
}

export const TOKEN_ADDRESSES = CHAIN_ID ? constructSameTokenAddressMap(CHAIN_ID) : [];

export const GYRO_ADDRESSES = CHAIN_ID ? constructSameAddressMap(POOLS_CONFIG, CHAIN_ID) : [];

export const BOND_ADDRESSES = CHAIN_ID ? constructSameAddressMap(BOND_CONFIG, CHAIN_ID) : [];

export const APOLLO_API_URL = CHAIN_ID ? constructSameAddressMap(APOLLO_API, CHAIN_ID) : [];

export const MULTICALL_ADDRESS = CHAIN_ID ? constructSameAddressMap(MULTICALL_CONFIG_ADDRESS, CHAIN_ID) : [];

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[CHAIN_ID];
