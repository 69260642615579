export default {
    portfolio: 'Dash',
    dex: 'DEX',
    privateSaleTitle: 'GYRO Private Sale',
    stake: 'Stake',
    bond: 'Bond',
    unstake: 'Unstake',
    combination: 'LP Bond',
    single: 'Single',
    connectWallet: 'Connect to a wallet',
    docs: 'Docs',
    code: 'Code',
    telegram: 'Telegram',
    vote: 'Vote',
    myWallet: 'My Wallet',
    switchWallet: 'Switch Wallet',
    amount: 'Amount',
    max: 'Max',
    usdtBalance: 'USDT balance',
    userPGyroBalance: 'pre-Gyro balance',
    purchase: 'Buy',
    approve: 'Approve',
    transactionSuccess: 'transaction success',
    transactionFailed: 'transaction failed',
    getAllowanceFailed: 'Get allowance information failed',
    approveSuccess: 'Set allowance successful',
    approveFailed: 'Set allowance failed',
    getPrivateSaleInfoFailed: 'Obtaining pre-Gyro information failed',
    greaterThanZero: 'Please enter a value greater than 0',
    vGyroOfferingTitle: 'GYRO IDO',
    userVGyroBalance: 'vGyro Balance',
    vGyroPrice: 'vGyro Price',
    exchange: 'Redeem pGYRO',
    exchangeSuccess: 'Exchange Success',
    exchangeFailed: 'Exchange Failed',
    redemptionRatio: 'Redemption Ratio',
    balance: 'Balance',
    walletBalance: 'Wallet Balance',
    myStake: 'Staked',
    hour: 'Hrs',
    minute: 'Mins',
    days: 'Days',
    currentIndex: 'Current Index',
    timeUntilRebase: 'Time until rebase',
    nextRebase: 'Next rebase yield',
    roi5: 'ROI (5-day rate)',
    apy: 'APY',
    bondTitle: 'How do you want to bond?',
    bondMsg: 'Bonds give you the opportunity to buy GYRO from the protocol at a discount. All bonds have a 5-day vesting period.',
    addLiquidity: 'Add Liquidity',
    pendingRewards: 'Pending Rewards',
    claimableRewards: 'Claimable Rewards',
    vestedTime: 'Time until fully vested',
    debtRatio: 'Debt Ratio',
    vestingTerm: 'Vesting Term',
    roi: 'ROI',
    bondPrice: 'Bond Price',
    marketPrice: 'Market Price',
    slippageTolerance: 'Slippage Tolerance',
    buyBond: 'Buy Bond',
    bondRedeem: 'Redeem',
    transaction: 'Transaction',
    maxMsg: 'Deposit amount is too large',
    minMsg: 'Deposit amount is too small',
    totalStaked: 'Total Staked',
    removeLiquidity: 'Remove Liquidity',
    fast: 'Buy Bond',
    invitationCode: 'Code (Optional)',
    discount: 'Discount',
    createMyCode: 'My Referral Code',
    price: 'Price',
    buy: 'Buy',
    codeMsg: 'Will get a recommended code after purchase',
    settings: 'Settings',
    slippage: 'Slippage',
    slippageMsg: 'Transaction may revert if price changes by more than slippage%',
    toggleExpertMode: 'Toggle Expert Mode',
    recipientAddress: 'Recipient Address',
    recipientAddressMsg: 'Choose recipient address. By default, this is your currently connected address',
    myRewards: 'Show Rewards',
    claimMyRewards: 'Claim Rewards',
    create: 'Create',
    reward: 'Reward',
    rewardMsg: 'Please enter the invitation code you want to extract',
    gyroPrice: 'GYRO Price',
    tvl: 'TVL',
    gyroStaked: 'GYRO Staked',
    marketCap: 'Market Cap',
    gyroTotalSupply: 'Total Supply',
    treasuryBalance: 'Treasury Balance',
    runway: 'Runway',
    stakeIncome: 'Next reward amount',
    whatYou: 'What You’ll Get',
    maxCanPurchase: 'Max Can Purchase',
    lpBond: 'LP Bond',
    redeemableNow: 'Redeemable Now',
    rewardsRemaining: 'Rewards Remaining',
    discord: 'Discord',
    buyGyro: 'Buy GYRO',
    singleBond: 'Single-Asset Bonds',
    pairBond: 'Pair-Asset Bonds',
    pair: 'Pair',
    lp: 'LP',
    bondDescribe: 'Buy LP bonds with a single asset',
    bondDescribe1: 'Buy LP bonds with pair-assets',
    bondDescribe2: 'Buy bonds with LP tokens',
    bondDescribe3: 'Buy bonds with single-assets',
    bonds: 'Bonds',
    BondsExtracted: 'Active Vestment',
    riskFreeValue: 'Risk Free Value',
    getInfoDataError: 'Get detailed data errors',
    bondSuccess: 'Purchase bonds success',
    bondError: 'Purchase bonds error',
    redeemSuccess: 'Receive bond success',
    redeemError: 'Receive bond error',
    optional: 'Optional',
    unstakeV1: 'unstake v1',
    migrationCountdown: 'Migration Countdown',
    migrationDescribeTitle: '48 hour window for migration',
    migrationDescribeText: 'During migration, APY will reduce for v1 Staking and increased for v2 Staking gradually across 6 epochs (48 hours).',
    migrationDescribeStep1: 'Unstake your v1 sGYRO',
    migrationDescribeStep2: 'Stake into v2',
    wrapAndUnwrap: 'Wrap/Unwrap',
    wrap: 'Wrap to gGYRO',
    unwrap: 'Unwrap to sGYRO',
    tokenSelect: 'Choose a Token'
};
